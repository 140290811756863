import Vue from 'vue'
import Element from 'element-ui'
// import { Button,Form,FormItem,Input,InputNumber,Message,Menu,Submenu,MenuItem,MenuItemGroup,
//     Breadcrumb,BreadcrumbItem,Table,TableColumn } from 'element-ui'


import '../element-variables.scss'
Vue.use(Element)

// Vue.use(Button)
// Vue.use(Form)
// Vue.use(FormItem)
// Vue.use(Input)
// Vue.use(InputNumber)
// Vue.prototype.$message = Message
// // Vue.use(Message)//若用use，则页面加载时会直接弹出来
// // Vue.use(MessageBox) //若用use，则页面加载时会直接弹出来
// Vue.use(Menu)
// Vue.use(Submenu)
// Vue.use(MenuItem)
// Vue.use(MenuItemGroup)
// Vue.use(Breadcrumb)
// Vue.use(BreadcrumbItem)