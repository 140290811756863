//侧边栏数据;后期存入数据库后，需要实际调用测测
//同时要注意，员工登录后访问没有权限的页面时，要判断跳转
export default{
    namespaced: true,
    state:{
        menuData:[]
    },
    mutations:{
        changeMenuData(state,playload){
            state.menuData = playload
        }
    }
}