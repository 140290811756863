<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script src="./utils/aliyun-oss-sdk6.18.1.min.js"></script>
<!--
<script src="https://unpkg.com/@wecom/jssdk"></script>

<script src="./utils/html2canvas.min.js"></script>
-->
<script>
export default {
  name: 'app',
  components: {
  }
}
</script>

<style lang="scss">
@import "style/common.scss";

html,
body,
#app {
  width: 100%;
  height: 100%;

  /* font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
</style>
