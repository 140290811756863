import Vue from 'vue'
import 'reset-css' //放在最上面，清除默认样式

import App from './App.vue'
import router from './router'
import store from './store/index.js'
import './plugins/element.js'

import Global from './Global.vue'
import axios from 'axios'
import { priceYuanToFen, comMomentFmt, teacherTotalNum } from './utils/util.js' //将某些方法改成全局，在html和js中都能用

// console.log(process.env,'-------process.env====main.js 配置变量后，需要buil才行，否则变量值为undefined')

const interfacePre = function () {
  let pre = 'https://prebapi.ke.live' // 本地和测试环境地址 prebapi.ke.live
  if (process.env.NODE_ENV == 'production') {
    pre = 'https://bapi.ke.live'
  }
  return pre;
}
// axios.defaults.withCredentials=true
const instance = axios.create({
  timeout: 5000, //5秒。若是开发阶段，时间长点也没事15秒也行
  baseURL: interfacePre(), //process.env.NODE_ENV == 'production' ? 'https://bapi.ke.live' : 'https://prebapi.ke.live',
  withCredentials: true, // send cookies when cross-domain requests 
  headers: { 'Content-Type': 'application/json;charset=UTF-8' }, //设置json格式。 默认设置请求头 为FormData 数据格式 application/x-www-form-urlencoded;charset=UTF-8 

})

instance.interceptors.request.use(function (config) {
  //若请求中需要token，则需要配置
  const token = localStorage.getItem('hkmanage_session')
  console.log(config.url, '----main.js axios request.use----token---', token)
  //登录接口、h5的支付接口、企微授权登录的两个接口，不需要token
  if (token && !config.url.endsWith('/login')
    && !config.url.endsWith('/customer/order/pay')
    && !config.url.endsWith('/wxCp/getWxCpLoginParams')
    && !config.url.endsWith('/wxCp/login')) {
    // config.headers.Cookie = token // 浏览器提示 Refused to set unsafe header "Cookie"
    config.headers["Authorization"] = token
  }


  config.data = JSON.stringify(config.data);  //用JSON替代了 qs处理，package.json中可以取消qs安装包了 "qs": "^6.5.2",

  return config;
}, function (error) {
  return Promise.reject(error);
});
instance.interceptors.response.use(res => {
  console.log(res, '========main.js response.use')
  let res_data = res.data
  if (res_data.code == 708) { //清除之前的缓存；然后跳转到登录页面；

    localStorage.removeItem('hkmanage_session')
    localStorage.removeItem('hkmanage_username')

    console.log('main.js 708 code,跳转到login页面了')
    router.push({ path: '/login' })
    return Promise.reject(res)

    // } else if (res_data.code != 708 && res_data.code != 200) {
    //   return res
  }

  return res; //return res是为了把 data:{code、message、result} 都传过去，因为是在Global的request方法处理的data

  // Promise.resolve(res)

}, err => {
  return Promise.reject(err)
})


//当前处于开发阶段，无需显示生产模式提示的信息。
Vue.config.productionTip = false

Vue.prototype.$http = instance
Vue.prototype.request = Global.methods.request;
Vue.prototype.requestDownloadGet = Global.methods.requestDownloadGet; //需要token的get方式下载
Vue.prototype.priceYuanToFen = priceYuanToFen
Vue.prototype.comMomentFmt = comMomentFmt
Vue.prototype.interfacePre = interfacePre
Vue.prototype.teacherTotalNum = teacherTotalNum

//全局自定义指令，为了让浏览器不报错
//el-radio-group在chrome浏览器下报错，需要官方去解决。报错信息：“元素上的Blocked aria-hidden，因为刚刚接收焦点的元素不能对辅助技术用户隐藏。避免在焦点元素或其祖先元素上使用aria-hidden”
Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    let ariaEls = el.querySelectorAll('.el-radio__original');
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
  }
});


new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
})
