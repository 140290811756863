import moment from "moment";
//价格 由 元 转换为 分。注意 (负)float*100后 无穷长的问题
export const priceYuanToFen = (yuan) => {
    let tmpPriceFen = '';

    let price = Math.abs(yuan); //先将价格转换为正数
    let priceArr = price.toString().split('.');
    if(priceArr.length==1){//没有小数
        tmpPriceFen = parseInt(price)*100
    }else{
        //有小数
        if(priceArr[1].toString().length==1){
            tmpPriceFen = priceArr[0]+''+priceArr[1]+'0'
        }else{
            tmpPriceFen = priceArr[0]+''+priceArr[1]
        }
    }
    if(yuan<0){
        tmpPriceFen = -tmpPriceFen;
    }
    return Number(tmpPriceFen);
}

//将 \n 转换为 br标签,用于v-html中
export const filterNtoBr = (msg) => {
    if (msg) {
        return msg.replace(/\n/g, "<br>");
    } else {
        return '';
    }
}
//获取某个时间 time 所在周的最后一天  (从周一开始) 返回周日晚上23:59:59 ---moment暂时没有引入
// export const getWeekLastDay = (time) =>{
//     let curr = new Date();
//     if(time){
//         curr = new Date(time);
//     }

//     let first = curr.getDate()-curr.getDay()+1; // +1表示 第一天从周一开始;去掉+1是从周日当做一周的第一天
//     let last = first + 6; //最后一天是第一天+ 6

//     let lastTime = moment(new Date(curr.setDate(last))).format('YYYY.MM.DD 23:59:59');

//     return new Date(lastTime).getTime()

// }


//获取某个时间 time 所在月的最后一天 ,返回Date格式
export const  getMonthLastDay = (time) => {
    let curr = new Date();
    if(time){
        curr = new Date(time);
    }
    let year = curr.getFullYear(),month = curr.getMonth(); //month---0~11
    let lastDay = new Date(year,month+1,0).getDate(); //getDate时,month需要加1.如果是1月,则返回 31

    let lastDateTime = new Date(year,month,lastDay,'23','59','59')

    return lastDateTime

}
// 获取某个时间 time 所在周的第一天  (从周一开始)  time--毫秒数 getTime  返回 周一早上0点0分0秒
export const getWeekFirstDay = (time) => {
    let curr = new Date();
    if(time){
        curr = new Date(time);
    }

    let first = curr.getDate()-curr.getDay()+1; // +1表示 第一天从周一开始;去掉+1是从周日当做一周的第一天

    let firstTime = moment(new Date(curr.setDate(first))).format('YYYY.MM.DD 00:00:00');

    return new Date(firstTime).getTime()

}

//moment自定义 格式化时间
export const comMomentFmt = (timestamp,fmt) => {
    if(!timestamp){
        timestamp = new Date().getTime()
    }

    if(!fmt){
        var tmDate = new Date( timestamp);  // 参数时间戳转换成的日期对象
        var Y = new Date().getFullYear(); //现在的年份
        if ( tmDate.getFullYear() == Y ) {
            fmt = 'MM.DD HH:mm'
        }else{
            fmt = 'YYYY.MM.DD HH:mm'

        }
    }

    return moment(new Date(timestamp)).format(fmt);
}

//老师总数，用于select中老师列表的pageSize.若以后老师添加，则只需更改这个数值即可
export const teacherTotalNum = ()=>{
    return 500
}
