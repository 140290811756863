import Vue from "vue";
import VueX from "vuex"
// import navCollapse from "./navCollapse/index";
import userMenuData from "./userMenuData/index"

Vue.use(VueX)

export default new VueX.Store({
    state:{
        isCollapse:false, //true-收起侧边栏；false-完整展示
        
    },
    modules:{
        userMenuData:userMenuData
    },
    mutations: {
        //收起或展开侧边栏
        TOGGLE_MENU(state) {
            state.isCollapse = !state.isCollapse;
        },
        
    },
    
})