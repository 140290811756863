import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// import { component } from 'vue/types/umd'
Vue.use(VueRouter)

// import { GetUserRouterApi } from '../request/api'
const routes = [
	{
		path: '/',
		// name:'mainlayout',
		component: resolve => require(['../views/layout/MainLayout.vue'], resolve),
		// component:()=>import(/* webpackChunkName: "mainlayout" */'../views/layout/MainLayout.vue'),
		children: [
			{
				path: '',// 工作台 默认首页，所以，这里path为空
				name: 'WorkBench',
				component: resolve => require(['../views/workbench/Index.vue'], resolve),
				meta: {
					title: '工作台'
				}
			},

			{
				path: '/douProduct',
				name: 'DouProduct',
				component: resolve => require(['../views/product/DouProduct.vue'], resolve),
				meta: {
					title: '抖店商品'
				}
			},
			{
				path: '/redProduct',
				name: 'RedProduct',
				component: resolve => require(['../views/product/RedProduct.vue'], resolve),
				meta: {
					title: '小红书商品'
				}
			},
			{
				path: '/statisOrder',
				name: 'Statistics',
				component: resolve => require(['../views/order/Statistics.vue'], resolve),
				meta: {
					title: '订单统计'
				}
			},
			{
				path: '/douOrder',
				name: 'DouOrder',
				component: resolve => require(['../views/order/DouOrder.vue'], resolve),
				meta: {
					title: '抖店订单'
				}
			},
			{
				path: '/redOrder',
				name: 'RedOrder',
				component: resolve => require(['../views/order/RedOrder.vue'], resolve),
				meta: {
					title: '小红书订单'
				}
			},
			{
				path: '/refund',
				name: 'Refund',
				component: resolve => require(['../views/order/Refund.vue'], resolve),
				meta: {
					title: '退费申请'
				}
			},
			{
				path: '/auditRefund',
				name: 'AuditRefund',
				component: resolve => require(['../views/order/AuditRefund.vue'], resolve),
				meta: {
					title: '退费审核'
				}
			},
			{
				path: '/studentmanage',
				name: 'StudentManage',
				component: resolve => require(['../views/students/Index.vue'], resolve),
				meta: {
					title: '学生管理'
				}
			},
			{
				path: '/tagmanage',
				name: 'TagManage',
				component: resolve => require(['../views/systemmanage/Tag.vue'], resolve),
				meta: {
					title: '标签管理'
				}
			},
			{
				path: '/settingmanage',
				name: 'SettingManage',
				component: resolve => require(['../views/systemmanage/Index.vue'], resolve),
				meta: {
					title: '账号管理'
				}
			},
			{
				path: '/addemployee',
				name: 'AddEmployee',
				component: resolve => require(['../views/systemmanage/AddEmployee.vue'], resolve),
				meta: {
					title: '编辑员工'
				}
			},
			{
				path: '/permissionmanage',
				name: 'PermissionSetting',
				component: resolve => require(['../views/systemmanage/Permission.vue'], resolve),
				meta: {
					title: '权限管理'
				}
			},
			{
				path: '/staticsSetting',
				name: 'StaticsSetting',
				component: resolve => require(['../views/systemsetup/Statics.vue'], resolve),
				meta: {
					title: '统计设置'
				}
			},
			{
				path: '/coursemanage',
				name: 'Coursemanage',
				component: resolve => require(['../views/coursemanage/Index.vue'], resolve),
				meta: {
					title: '课程管理'
				}
			},
			{
				path: '/courselessonlist',
				name: 'CourseLessonList',
				component: resolve => require(['../views/coursemanage/LessonList.vue'], resolve),
				meta: {
					title: '查看课节'
				}
			},
			{
				path: '/midterm',
				name: 'CourseMidTerm',
				component: resolve => require(['../views/coursemanage/MidTerm.vue'], resolve),
				meta: {
					title: '期中报告'
				}
			},
			{
				path: '/transferClass',// 教学管理
				name: 'TransferClass',
				component: resolve => require(['../views/teachmanage/Transfer.vue'], resolve),
				meta: {
					title: '转班列表'
				}
			},
			{
				path: '/joinClass',
				name: 'JoinClass',
				component: resolve => require(['../views/teachmanage/Join.vue'], resolve),
				meta: {
					title: '插班列表'
				}
			},
			{
				path: '/CourseData', //教学数据
				name: 'CourseData',
				component: resolve => require(['../views/statistic/CourseData.vue'], resolve),
				meta: {
					title: '行课数据'
				}
			},
		]
	},

	{
		path: '/login',
		name: 'Login',
		component: resolve => require(['../views/login/Login.vue'], resolve)//使用这个为啥页面不出来呢？resolve => require(['../views/login/Login.vue'], resolve)
	},
	{
		path: '/wxCp', //企微登录
		name: 'EnWxLogin',
		component: resolve => require(['../views/login/EnWxLogin.vue'], resolve)
	},
	{
		path: '/wxCpLogin', //企微登录回调页面
		name: 'WxCpLoginCallback',
		component: resolve => require(['../views/login/WxCpLoginCallback.vue'], resolve)
	},


]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

/**
 * 拦截守卫，管理系统两个常见的经典逻辑
 * 1，若用户访问登录页面，但有token，跳转到首页
 * 2，若未登录情况下（没有token），访问后台页面（除login页），跳转到登录页
 */
router.beforeEach(async (to, from, next) => {

	// console.log(from, '---from----to----', to)
	const token = localStorage.getItem('hkmanage_session')
	console.log(to.path, '========to.path')
	if (to.path !== "/login" && to.path !== "/wxCp" && to.path !== "/wxCpLogin" && to.path !== "/404") { //存储访问的页面路径，用于登录后自动跳回去
		localStorage.setItem("hkmanage_path_to", to.fullPath)
	}
	let preUrl = localStorage.getItem("hkmanage_path_to")

	if (to.path.indexOf('/h') != -1
		|| to.path.indexOf('/wxCpLogin') != -1
		|| to.path.indexOf('/404') != -1) { //访问的h5页面、企微回调页面，直接访问，不需要登录
		next()
		return
	}
	if ((to.path === "/login" || to.path === "/wxCp") && token) { //登录后跳转到对应的页面

		if (preUrl) {
			if (preUrl.endsWith('/login') || preUrl.endsWith('/wxCp')) { //上个页面是登录，则登录后进入首页
				next('/')
			} else {
				next(preUrl)
			}
		} else {
			next('/')
		}
		return
	}

	//本地和预发，若没有token，访问的也不是login页面，则直接跳转到login。因为无法访问wxCp授权页面，域名不一致
	if (process.env.NODE_ENV !== 'production' && to.path !== "/login" && !token) {
		next('/login')
		return
	}

	//线上环境，若没有token，访问的也不是wxCp页面，则直接跳转到wxCp
	if (process.env.NODE_ENV == 'production' && !token) {
		//企微绑定的地址，只用wxCp
		if (location.origin == 'https://b.shiwuyingxiaocehua.top') {
			if (to.path !== "/wxCp") {
				next('/wxCp')
				return
			}
		} else { //浏览器访问的 b.kelive 域名，直接去往登录页
			if (to.path !== "/login") {
				next('/login')
				return
			}
		}

	}

	next()

})


export default router