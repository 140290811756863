<template>
  <div>

  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "Global",
  methods: {
    request(opt) {
      let self = this;
      //添加请求token
      switch (opt.method) {
        case 'post':
          self.$http.post(opt.url, opt.param).then(function (response) {
            opt.success(response.data)
          }).catch(function (error) {
            opt.error(error);
          });
          break;
        case 'get':
          // console.log('global-----get');
          self.$http.get(opt.url, opt.responseType)
            .then(function (response) {
              opt.success(response) //注意，get只返回response
            }).catch(function (error) {
              opt.error(error);
            });
          break;
      }

    },

    //get形式的下载文件方法
    requestDownloadGet(opt) {
      let token = localStorage.getItem('hkmanage_session')
      axios
        .get(opt.url, {
          responseType: 'blob',
          // 请求头添加token
          headers: { Authorization: token }
        })
        .then((res) => {
          opt.success(res)
          // if (res.status === 200) {
          //   var elink = document.createElement('a');
          //   // 使用后端提供的文件名  命名
          //   const fileName = res.headers['content-disposition'].split('=')[1];
          //   elink.style.display = 'none';
          //   // 文件下载地址赋值
          //   elink.href = window.URL.createObjectURL(res.data);
          //   let str = sRValue.value + fileName;
          //   str = str.substring(0, str.length - 1);
          //   // 自定义文件名
          //   elink.download = str;
          //   // 调用a标签单击事件进行下载文件
          //   elink.click();
          //   message.success('文件导出成功');
          // }
        })
        .catch((err) => {
          opt.error(err)
          // console.log(err, '打印错误信息');
          // message.error(`数据导出失败，${err.message}`);
        });

    }

  }
}
</script>
